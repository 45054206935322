<template>
  <div>
    <v-breadcrumbs
      :items="breadcrumbs"
      class="grey lighten-2 white--text"
    ></v-breadcrumbs>
    <div class="pa-4">
      <v-card elevation="1">
        <div class="wrapper">
          <h1 class="mb-2">Importar Dados</h1>
          <h4 class="mb-4">
            Envie um arquivo Excel para iniciar o processo de importação. A
            importação pode levar alguns minutos. Não feche esta tela até que o
            processo seja concluído.
          </h4>

          <div class="card">
            <p>Selecione o arquivo e clique em "Importar" para continuar.</p>
            <div class="d-flex">
              <v-file-input
                class="mr-4"
                accept=".xls, .xlsx"
                prepend-icon=""
                label="Arquivo XLS"
                outlined
                :chips="true"
                small-chips
                dense
                hide-details
                v-model="importDataFile"
              ></v-file-input>
              <v-btn
                color="primary"
                @click="importBackup()"
                :loading="loading"
                :disabled="!importDataFile"
              >
                Importar
              </v-btn>
            </div>
          </div>
        </div>
    </v-card>
    </div>
    
    <LoadingBackup
      :dialog="loading"
      :dialogTitle="dialogTitle"
      :dialogMessage="dialogMessage"
    />
    <AlertSuccess
      :dialog="success"
      dialogMessage="Importe de produtos realizado com sucesso."
      @close="success = false"
    />
    <AlertError :alertError="error" :messageError="messageError" />
  </div>
</template>

<script>
import { importProduct } from "@/services/product.js";
export default {
  name: "ImportarBackup",
  data: () => ({
    breadcrumbs: [
      {
        text: "Importar Backup",
        disabled: true,
        to: "",
      },
    ],
    importDataFile: null,
    loading: false,
    messageError: "Ocorreu um erro ao importar arquivo",
    error: false,
    dialogTitle: "Importar Dados...",
    dialogMessage:
      "Aguarde alguns minutos enquanto a importação está sendo gerada. Não feche esta tela até que o processo seja concluído.",
    success: false,
  }),
  methods: {
    async importBackup() {
      let data = new FormData();
      if (this.importDataFile) {
        data.append("file", this.importDataFile);
      }
      try {
        this.loading = true;
        const resp = await importProduct(data);
        if (resp) {
          this.success = true;
          this.importDataFile = null;
        }
      } catch (e) {
        this.loading = false;
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  flex-direction: column;
  max-width: 680px;
  margin: 0 auto;
  text-align: center;
}
::v-deep .v-btn {
  height: 40px !important;
}
</style>
